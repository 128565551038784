import React from 'react';
import Layout from '../components/Layout';

const Corona  = () => (
  <Layout>
  <article id="main">
    <header>
      <h2>Moving during the Corona Virus</h2>
      <p>How you can safely hire movers during the Covid19 outbreak</p>
    </header>

    <section className ="wrapper style5">
      <div className="inner">
        <h3>Were you planning on moving over the next few weeks before this whole Corona crisis began to affect the United States? </h3>
        <h3>Not sure if you can still hire movers during the Coronavirus?</h3>
          <p>As a moving company who has laboured with the decision of what’s the best choice to make for his business as well as for the community at large, there are some things we can all do to figure out the best course of action. Below we will take a look at a few of the important questions to ask yourself about your upcoming move to see if it makes sense to schedule movers right now during the Corona epidemic. If you will need to move during the Corona virus, then there are certain steps we can take to ensure everyones safety. </p>
        <h3>Will movers still be moving during the coronavirus?</h3>
        <p>The short answer is yes. Many of even the biggest and best moving companies out there such as <a href="allied.com">Allied </a>, <a href="www.unitedvanlines.com">United Van Lines</a>, <a href="www.bekins.com">Bekins</a>, and quite a few others are still operating. The reason for this is simple. Keeping people sheltered and with homes to live in is absolutely essential. Like the age old saying “good fences make good neighbors.” Our nations truckers, and manual laborers are some of the bravest, hardest working folks out there and we will not rest until every American who needs to get his things from Alpha to Zulu is taken care of! Many places such as San Francisco have said <a href="www.sfchronicle.com/business/article/Bay-Area-residents-can-still-move-homes-during-15152555.php"> residents can still move </a> That being said we cannot in good faith tell you at this point in time how much longer we will be able to operate. Any week now the federal government and the Department of Transportation, as well as your local government may put into place new policies which make it tougher for us to do our jobs. These rules are for the good of our country and the public’s health! So if you need to move and you know that it can’t wait a few months, now is the time to plan your move asap.</p>
        <h3>Do you really need to move right now?</h3>
        <p>If you are in a position where you do not need to move, and can afford to wait it out a few months you should wait. If you are over the age of 65 and or have any preexisting medical conditions you should speak with your doctor and weigh the pros and cons of moving right now. If it’s possible to push your move off, as the guys who want to do business with you - you should! If you need to move now, we are happy to help you through this difficult time. Let's work together to do it the right way so that we all stay safe. </p>
        <h3>If you move now, how will you get to your new home?</h3>
        <p>With everything that has been going on you may not be able to, and may not want to fly from one place to another. So in planning your move you will want to make sure that your automobile is in good working order. Are you due for an oil change? Are all of your plates and tags up to date? Next, you should plan for hotels along the way. Try to find places that have flexible check in hours. While <a href="www.airbnb.com">Airbnb </a> is usually a great route for finding places to stay, during a time like this it may be best to get a private room. <a href="www.priceline.com">Priceline </a>is a great tool for getting deals on hotel rooms around the country. Make sure to bring a cooler and also pack some non perishables so that you will have food and snacks without having to make unnecessary stops. </p>
        <h3>What can we different during Corona virus do to make sure your move goes safe?</h3>
        <ul>
          <li>Have hand sanitizer and paper towels available for the movers</li>
          <li>Have lysol and disinfectant sprays for when the movers are finished</li>
          <li>Make sure to let us know if you or your family are feeling sick</li>
          <li>Avoid using free boxes instead <a href="/fullpacking">get moving boxes</a> directly from the movers</li>
          <li>Plastic totes and tubs are easier to sterilize than moving boxes</li>
          <li>Wrap your items in plastic bags as an added barrier of protection during the moving process</li>
          <li>Wipe down items and boxes before the movers arrive</li>
          <li>Practice social distancing</li>
          <li>Really focus on practicing social distancing!</li>
          <li>Make payments via credit cards or online payment methods like Docusign instead of cash</li>
          <li>Get moving quotes over the phone and avoid in home estimates to limit your exposure</li>
          <li>Take good pictures of your home to help get an <a href="/get-moving-quote">accurate moving quote</a> over the phone</li>
        </ul>
        <h3>How to stay safe receiving your delivery?</h3>
        <p>The number one thing we can do to make sure the delivery is safe is to be open and honest about how we are feeling. If you have a fever, or are not feeling well please let us know so that we can plan accordingly. Social distancing is something we can easily practive upon the delivery. Once the movers have everything in place you can wipe everything down with lysol. Make sure to wash your hands properly and avoid touching your face. Even during something as crazy as a global epidemic life must go on and so by following a few simple procedures we can all stay safe. </p>
      </div>
    </section>
    </article>
  </Layout>
)
export default Corona
